 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
 }

 body {

     font-family: "Oswald", sans-serif !important;
 }

 .h-20 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     line-height: 30px;


 }

 .search-input input {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 21px;
     text-align: left;
     background: #F5F5F5;
     width: 300px;
     color: #8391A1;
     padding-left: 38px;
     padding-right: 75px;
     height: 40px;
     border-radius: 8px 8px 8px 8px;
     border: 1px solid #E8ECF4;
 }

 .input-filter-box .filter-dropdowns img {
     width: 20px;
 }

 .search-input input::placeholder {
     color: #8391A1;
 }

 .search-input img {
     position: absolute;
     top: 14px;
     bottom: 0;
     left: 15px;
 }

 .search-input {
     position: relative;
 }

 .search-input input:focus {
     outline: none;
     text-align: center;
 }

 .filter-dropdowns a {
     padding: 3px 15px;
     border: transparent;
     background: #103D56;
     dius: 5px;
     margin-top: 0px;
     background: #103D56;
     display: block;
     border-radius: 5px;

 }

 .header-inner-left {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .h-30 {

     font-size: 25px;
     font-weight: 600;
     line-height: 36px;


 }

 .h-25 {
     /* font-family: Poppins; */
     font-size: 22px;
     font-weight: 600;
     line-height: 37.5px;
     text-align: left;
     color: #103D56;
     margin: 0;
 }

 .header-right-links a:first-child {
     background: #F5F5F5;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
     color: #8391A1;
     text-decoration: none;
     padding: 7px 25px;
     text-align: center;
     margin-right: 10px;
     border-radius: 5px;
 }

 .header-right-links a:last-child {

     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     color: #fff;
     line-height: 24px;

     text-decoration: none;
     padding: 7px 25px;
     text-align: center;
     border-radius: 5px;
     background: #103D56;
 }

 .inner-header {
     box-shadow: 0px 2px 20px 0px #00239526;
     background: #103D56;
     border-radius: 5px;
     z-index: 111;
     position: relative;
     padding: 10px 0px 10px 0px;
 }

 .light-background {
     height: 100%;
     /* background: #abcee5; */
 }

 .input-filter-box .filter-dropdowns {
     position: absolute;
     top: 5px;
     right: 5px;
 }

 .input-filter-box {
     position: relative;
     z-index: 11;
     margin-right: 13px;
 }

 /* .header {
    padding: 15px 0;
} */
 .card-heading h2 {
     margin: 0;
     color: #103D56;
     font-family: Oswald;
     font-size: 20px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;

 }

 .cmn-card {
     box-shadow: 0px 2px 20px 0px #00239526;
     background: #FFFFFF;
     padding: 20px 20px;
     border-radius: 5px;
     margin-top: 70px;
 }

 .banner-desc img {
     width: 50px !important;
     height: 50px !important;
     object-fit: cover;
 }

 .banner-desc h2 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     max-width: 450px;
     line-height: 27px;
     text-align: left;
     margin: 0;
 }

 .banner-desc {
     display: flex;
     gap: 10px;
     position: absolute;
     padding: 13px 20px;
     bottom: 10px;
     width: fit-content;
     margin: auto;
     background-color: #fff;
     border-radius: 5px;
     left: 0;
     right: 0;
 }

 .banner-owl .owl-dots {
     position: absolute;
     top: 10px;
     left: 0;
     right: 0;
 }

 .banner-owl button.owl-prev {
     position: absolute;
     top: 0;
     left: 10px;
     bottom: 0;
 }

 .banner-owl button.owl-next {
     position: absolute;
     top: 0;
     right: 10px;
     bottom: 0;
 }

 .banner-owl .owl-nav [class*='owl-']:hover {
     background: transparent !important;
 }

 .banner-slider::before {
     content: "";
     position: absolute;
     background: #000000;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     border-radius: 5px;
     opacity: 0.2;
 }

 .banner-slider img {
     width: 100%;
     height: 370px;
     object-fit: cover;
     border-radius: 5px;
 }

 .home-players h2 {
     background: #103D56;
     color: #fff;
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 400;
     line-height: 27px;
     padding: 8px 15px;
     border-radius: 5px;
     margin: 0;
 }

 .players-inner-box {
     border-radius: 5px;
     padding: 10px 12px;
     box-shadow: 0px 2px 20px 0px #00239526;
     margin-bottom: 10px;
     display: flex;
     background-color: #fff;
     gap: 10px;
     align-items: center;
 }

 .players-inner-box img {
     width: 50px;
     height: 50px;
     border-radius: 5px;
     object-fit: cover;
 }

 .players-inner-box h3 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
     margin: 0;
 }

 .players-inner-box h3 span {
     color: #1D161780;
 }

 .players-scroll-sec {
     background: #F5F5F5;
     padding: 18px;
     height: 327px;
     overflow-y: scroll;
 }

 .add-cards {
     position: relative;
 }



 .add-banner-desc img {
     width: 50px !important;
     height: 50px !important;
     object-fit: cover;

 }

 .add-banner-desc h2 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;
     max-width: 200px;
     line-height: 1.4;

     margin: 0;
 }

 .add-banner-desc {
     display: flex;
     gap: 10px;
     position: absolute;
     align-items: center;
     padding: 13px 20px;
     bottom: 10px;
     width: fit-content;
     margin: auto;
     background-color: #fff;
     border-radius: 5px;
     left: 0;
     right: 0;
 }

 .add-cards img {
     width: 100%;
     height: 240px;
     border-radius: 5px;
 }

 .play-card-sec h3 {
     /* font-family: Poppins; */
     font-size: 22px;
     font-weight: 600;
     line-height: 37.5px;
     text-align: left;
     color: #103D56;
 }

 .play-inner-box {
     display: flex;
     align-items: center;
     gap: 15px;
 }

 .play-inner-box h4 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 600;
     line-height: 20px;
 }

 .play-inner-box p {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 500;

 }

 .play-inner-box p span {
     color: #1D161780;

 }

 .badge-img img {
     width: 30px;
     height: 30px;
     object-fit: cover;
 }

 .badge-img {
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 5px;
     border-radius: 10px;
 }

 .play-inner-box>img {
     width: 80px;
     height: 80px;
     object-fit: cover;
     border-radius: 10px;
 }

 .transfer-box {
     box-shadow: 0px 2px 20px 0px #00239526;
     border-radius: 10px;
 }

 .play-inner-box {
     display: flex;
     align-items: center;
     gap: 15px;
     margin-bottom: 10px;
     justify-content: space-between;
     /* box-shadow: 0px 2px 20px 0px #00239526; */
     border-radius: 10px;
     padding: 12px;
     position: relative;
     border-bottom: 1px solid #DEDEDE;
     border-radius: 0px;
 }

 .play-scroll-box {
     overflow-y: scroll;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 15px;
     height: 376px;
     border-radius: 10px;
 }

 .play-scroll-box::-webkit-scrollbar {
     display: none;
 }

 .play-images img {
     width: 100%;
     height: 300px;
     border-radius: 10px;
     object-fit: cover;
 }

 .play-images {
     position: relative;
 }

 .players-value {
     background: #103D56;
     padding: 10px;
     border-radius: 5px;
     width: 833px;
     position: absolute;
     bottom: 10px;
     left: 0;
     right: 0;
     margin: auto;
 }

 .players-value h3 {
     /* font-family: Poppins; */
     font-size: 30px;
     font-weight: 600;
     line-height: 45px;
     text-align: center;
     color: #fff;
     margin: 0;
 }

 .view-btn h3 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 500;
     text-align: left;
     color: #868686;
     margin: 0;
     display: flex;
     align-items: center;
     gap: 7px;
 }

 .news-inner-box {
     display: flex;
     align-items: center;
     gap: 20px;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 10px;
     border-radius: 10px;
 }

 .news-inner-box h3 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     line-height: 27px;
     margin: 0;
 }

 .news-inner-box p {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
     max-width: 400px;
     margin: 0;
     padding-top: 5px;
 }

 .news-inner-box>img {
     width: 158px;
     height: 120px;
     object-fit: cover;
     border-radius: 10px;
 }

 .news-inner-box .badge-img {
     position: relative;
     top: -13px;
     right: -15px;
 }

 footer {
     padding-top: 30px;
 }

 footer p {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
     text-align: left;
     color: #fff;
     max-width: 300px;
 }

 .inner-menu h3 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 27px;
     color: #DFDFDF;
     margin: 0;
 }

 .inner-menu ul {
     padding: 0;
     list-style: none;
 }

 .inner-menu ul li a {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
     color: #DFDFDF;
     text-decoration: none;
 }

 .footer-menu {
     display: flex;
     gap: 100px;
     justify-content: center;
     margin: 40px 0;
 }

 .main-section {
     height: 100%;
     /* background: linear-gradient(90deg, #8BB9D6 12.5%,#103D56 87.5%), #FFF; */
     display: flex;
     align-items: center;
     padding: 100px 0;

 }

 .main-form {
     padding: 40px 0;
 }

 .cmn-form {
     background: #fff;
     border-radius: 10px;
     box-shadow: 0 0 10px 0 #0000001a;
     padding: 35px 60px;
     width: 600px;
 }

 .pass-show {
     background: none;
     border: none;
     position: absolute;
     right: 45px;
     top: 32px;
 }

 .form-inner img {
     left: 14px;
     position: absolute;
     top: 17px;
 }

 .form-inner {
     position: relative;
     width: 100%;
 }

 .forgot-password {
     color: #103D56;

     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;


     text-decoration: none;
 }

 .cmn-red-btn {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     width: 100%;
     color: #fff;
     line-height: 24px;
     text-decoration: none;
     border: none;
     display: block;
     padding: 10px 25px;
     text-align: center;
     border-radius: 5px;
     background: #103D56;
 }


 .h-27 {
     /* font-family: Poppins; */
     font-size: 27px;
     font-weight: 600;
     margin: 0;
 }

 .sub-16 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
     margin: 0;

 }

 .form-inner input {
     background: none;
     padding: 9px 10px;
     border: none;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);

 }

 .form-inner input::placeholder {
     color: #D6D6D6;

 }



 .form-inner textarea {
     background: none;
     padding: 9px 10px;
     border: none;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
     resize: none;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .form-inner textarea::placeholder {
     color: #D6D6D6;

 }

 .or-sec {
     position: relative;
     margin-top: 30px;
 }

 .or-sec p {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 22px;
     text-align: center;
     width: 30px;
     z-index: 1111;
     margin: auto;
     background: #fff;
     position: relative;
 }

 .or-sec::before {
     content: "";
     position: absolute;
     background: #D6D6D6;
     width: 100%;
     height: 1px;
     top: 11px;
 }

 .bottom-btns button {
     box-shadow: 0px 2px 20px 0px #00239526;
     width: 100%;
     align-items: center;
     display: flex;
     justify-content: center;
     height: 50px;
     background: #fff;
     gap: 15px;
     text-decoration: none;
     border-radius: 10px;
     border: none;
 }

 .bottom-btns button p {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
     color: #262626;
     text-align: center;
     margin: 0;
 }

 .bottom-btns {
     display: flex;
     gap: 20px;
     margin-top: 30px;
 }

 .no-acc {
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 30px;
 }

 .no-acc h4 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 22px;
 }

 .no-acc a {
     color: #103D56;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-decoration: none;
     margin-left: 2px;
 }

 .no-acc button {
     color: #103D56;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-decoration: none;
     margin-left: 2px;
     background: none;
     border: none;
 }

 .text-danger {
     font-size: 14px !important;
     margin-top: 6px;
     font-weight: 400 !important;
 }

 .opt-verify-fields input {
     /* font-family: Poppins; */
     font-size: 20px;
     font-weight: 500;
     /* padding: 0 25px; */
     margin: 0 10px;
     line-height: 21px;
     width: 100% !important;
     text-align: left;
     border-radius: 10px;
     border: 2px solid #fff;
     color: #000 !important;
     height: 50px;
     background: #fff;
     border: none;
     text-align: center;
     box-shadow: 0px 2px 20px 0px #00239526;
 }

 .image-upload-inner.profile {
     height: 170px;
 }

 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }

 .opt-verify-fields input:focus {
     box-shadow: none;
     border: 2px solid #34617c;
     background: #fff;

     outline: none;
     box-shadow: 0px 2px 20px 0px #00239526;
 }

 .opt-verify-fields input::placeholder {

     font-size: 13px;
     color: #8391A1 !important;

 }

 .opt-verify-fields {
     display: flex;
     gap: 10px;
     margin: 40px 0;
 }

 .accept-condition {
     display: flex;
     gap: 10px;
     align-items: baseline;
     margin-top: 25px;
 }

 .accept-condition p {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     color: #28282B;
     margin: 0;
 }

 .accept-condition p a {
     color: #103D56;
     text-decoration: none;
     font-weight: 600;
 }

 .image-upload-input {
     margin: auto auto 40px;
     position: relative;
     width: 132px;
 }

 .image-upload-view .view-img {
     border-radius: 100px;
     bottom: 0;
     height: 130px;
     left: 0;
     object-fit: cover;
     /* position: absolute; */
     right: 0;
     top: 0;
     width: 130px;
     position: relative;
 }

 .image-upload-input .image-upload-camera {
     bottom: 0;
     cursor: pointer;
     position: absolute;
     right: 5px;
 }

 .image-upload-camera input {
     background: none;
     border: none;
     box-shadow: none;
     cursor: pointer;
     height: 100%;
     opacity: 0;
     padding: 0;
     position: absolute;
     width: 100%;
 }

 .licensed-sec {
     display: flex;
     align-items: center;
     justify-content: space-between;
     /* box-shadow: 0px 2px 20px 0px #00239526; */
     background: none;
     padding: 9px 10px;
     border: none;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
     border-radius: 10px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
     width: 100%;
 }

 .licensed-sec h3 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
     margin: 0;
     width: 70%;
 }

 .licensed-sec .form-check.form-switch {
     padding: 0;
 }

 .licensed-sec .form-check.form-switch input {
     width: 35px;
     height: 18px;
 }

 .licensed-sec .form-check.form-switch input:checked {
     background-color: #4C8BB480;
     background-position: 19px center;
     border: none;
 }

 .licensed-sec .form-check.form-switch input:focus {
     /* box-shadow: none; */
     box-shadow: 0px 2px 20px 0px #00239526;
 }

 .licensed-sec .form-switch .form-check-input {
     --bs-form-switch-bg: url("../images/toggle.png");
     background-position: 3px center;
     background-size: auto;
 }

 .form-inner select {
     box-shadow: 0px 2px 20px 0px #00239526;
     background-color: #fff;
     padding: 9px 10px;
     border: none;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
 }

 .cmn-form-fields p {
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 500;
     line-height: 18px;
     color: #000000;
     /* width: 100%; */
     min-width: 50px;
 }

 .login-right-head button {
     padding: 8px 20px;
     border: transparent;
     background: #fff;
     color: #103D56;
     display: flex;
     align-items: center;
     gap: 10px;
     border-radius: 10px;
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 21px;
 }

 .user-profile {
     display: flex;
     align-items: center;
     gap: 14px;
 }

 .user-profile img {
     width: 40px;
     height: 40px;
     border-radius: 40px;
     object-fit: cover;
 }

 .user-profile h3 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 700;
     line-height: 24px;
     margin: 0;
 }

 .user-profile p {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 18px;
     margin: 0;
 }

 .login-right-head {
     display: flex;
     align-items: center;
     gap: 15px;
 }

 .notify-sec {
     display: flex;
     gap: 12px;
 }

 .home-tab-filter ul {
     padding: 0;
     list-style: none;
     display: flex;
     gap: 55px;
     margin: 0;
 }

 .home-tab-filter {
     padding-top: 12px;
     display: flex;
     justify-content: start;
 }

 .home-tab-filter ul li a svg {
     margin-right: 16px;
 }

 .home-tab-filter ul li a {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     color: #fff;
     line-height: 27px;
     text-decoration: none;
 }

 .header-bottom-tab a {
     display: flex;
     width: 100%;

     align-items: center;
     justify-content: center;
     height: 44px;
     border-radius: 5px;
     /* font-family: Poppins; */
     /* border: 1px solid #103D56; */
     font-size: 15px;
     font-weight: 500;
     gap: 11px;
     line-height: 21px;
     text-align: center;
     text-decoration: none;
     color: #103D56;
 }

 .header-bottom-tab {
     box-shadow: 0px 2px 20px 0px #00239526;
     z-index: 11;
     position: relative;
     display: flex;
     justify-content: space-between;
 }

 .home-tab-filter .menu-btn.bar_active {
     font-weight: 600;
     color: #fff;
 }

 .home-tab-filter ul li .menu-btn svg path {
     fill: #fff;
 }

 .play-card-sec table {
     display: block;
     width: 100%;
 }

 .play-card-sec table tr {
     display: block;
     width: 100%;
     background: #DCEAF3;
     padding: 8px;
 }

 .play-card-sec table tr th {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
 }

 .transfer-head {
     background: #DCEAF3;
     border-radius: 10px 10px 0 0;
     padding: 7px 15px;
 }

 .transfer-head h3 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 600;
     line-height: 24px;
     margin: 0;
 }

 .play-data {
     display: flex;
     align-items: center;
     gap: 15px;
 }

 .play-data h4 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 600;
     line-height: 21px;
     text-align: left;
     margin: 0;
 }

 .play-data p {
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 400;
     line-height: 18px;
     margin: 0;
 }

 .play-data img {
     width: 38px;
     height: 38px;
     object-fit: cover;
     border-radius: 5px;
 }

 .transfer {
     display: flex;
     align-items: center;
     gap: 12px;
 }

 .transfer p {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;


 }

 .compare-players-bottom h4 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
     /* text-align: center; */
     margin: 0;
     width: 280px;
     color: #fff;
 }

 .compare-box {
     display: flex;
     align-items: center;
     width: calc(100% - 200px);
     gap: 15px;
     margin-bottom: 10px;
     justify-content: space-between;
     border-radius: 10px;
     padding: 12px;
 }

 .compare-box p {
     margin: 0 !important;
     font-family: Poppins !important;
     font-size: 15px !important;
     font-weight: 500 !important;
     line-height: 21px !important;
     color: #ffff;
     width: 340px;

 }

 .compare-players-main {
     box-shadow: 0px 2px 20px 0px #00239526;
     border-radius: 10px;
     padding: 20px 30px;
     height: 477px;
     overflow-y: scroll;
 }

 .compare-players-main::-webkit-scrollbar {
     display: none;
 }

 .play-inner {
     width: 120px;
     cursor: pointer;
     height: 120px;
     box-shadow: 0px 2px 20px 0px #00239526;
     border-radius: 80px;
     display: flex;
     position: relative;
     align-items: center;
     justify-content: center;
 }

 .play-inner input {
     opacity: 0;
     width: 100%;
     height: 100%;
     z-index: 11;
     cursor: pointer;
 }

 .play-inner .prev-img {
     position: absolute;
     width: 100%;
     height: 100%;
     border-radius: 76px;
 }

 .play-inner .up-icon {
     position: absolute;
     left: 0;
     right: 0;
     margin: auto;
     top: 0;
     bottom: 0;
 }

 .play-box-wrap h4 {
     font-size: 15px;
     font-weight: 600;
     line-height: 24px;
     margin-top: 14px;
 }

 .players-top-sec {
     display: flex;
     justify-content: space-between;
     text-align: center;
     margin-bottom: 15px;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 15px 17px;
     border-radius: 14px;
 }

 .view-link {
     /* font-family: Poppins; */
     font-size: 17px;
     font-weight: 400;
     line-height: 30px;
     color: #868686;
     text-decoration: none;
     margin: auto;
     display: table;
     margin-top: 20px;
 }

 .card-heading .filter {
     box-shadow: 0px 1px 10px 0px #00000033;
     padding: 7px 13px;
     border-radius: 10px;
     margin-bottom: 5px;
 }

 .compare a {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 27px;
     color: #00000080;
     text-decoration: none;
     margin-right: 20px;
 }

 .compare {
     padding-bottom: 5px;
     border-bottom: 2px solid #D5D5D5;
 }

 .player-upload-div {
     background: #fff;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width: 260px;
     height: 170px;
     border: 3px solid #103D56;
     position: relative;
     border-radius: 20px;
 }

 .player-upload-div p {
     /* font-family: Poppins; */
     font-size: 14px !important;
     font-weight: 400 !important;
     line-height: 30px;
     color: #103D56;
     text-align: center;

 }

 .player-upload-div input[type="file"] {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     cursor: pointer;
     z-index: 1;
     opacity: 0;
 }

 .player-upload-div .prev-img {
     position: absolute;
     width: 100%;
     height: 100%;
     object-fit: contain;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     border-radius: 10px;
 }

 .compare-bottom p {
     /* font-family: Poppins; */
     font-size: 25px;
     font-weight: 600;
     line-height: 37.5px;
     margin: 0;
 }

 .compare-bottom {
     margin: 100px 80px 15px 80px;
 }

 .comp-btn a {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     display: block;
     color: #fff;
     line-height: 24px;
     margin-top: 50px;
     text-decoration: none;
     padding: 10px 130px;
     text-align: center;
     border-radius: 5px;
     background: #103D56;
 }

 .compare .active-compare {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 27px;
     display: inline-block;
     color: #103D56;
     position: relative;
 }

 .compare .active-compare::before {
     content: "";
     width: auto;
     height: 2px;
     background: #103D56;
     position: absolute;
     left: 0;
     right: 0;
     bottom: -7px;
 }

 .trophy-sec h5 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 600;
     line-height: 21px;
     margin: 0;
 }

 .trophy-sec img {
     margin-right: 10px;
 }

 .nav-tabs {
     border-bottom: 2px solid #D5D5D5;
 }

 .nav-tabs li.nav-item .nav-link.active {
     border: none !important;
     border-bottom: 3px solid#103D56 !important;
     color: #103D56;

 }

 .nav-tabs li.nav-item button {
     border: none;
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 27px;
     text-align: left;
     color: #00000080;
     margin-right: 30px;
 }

 .light-blue-btn {
     background: #103D56;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
     line-height: 24px;
     color: #fff;
     padding: 8px 25px;
     border: none;
     border-radius: 10px;
     text-decoration: none;
 }

 .light-blue-btn-big {
     background: linear-gradient(90deg, #8BB9D6 12.5%, #103D56 87.5%);
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 27px;
     text-align: center;
     color: #fff;
     border: none;
     min-width: 370px;
     height: 46px;
     border-radius: 5px;
     margin-bottom: 21px;
 }

 .messages-total h5 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
     margin-top: 15px;
 }

 .message-box {
     display: flex;
     align-items: center;
     gap: 15px;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 8px 10px;
     border-radius: 15px;
 }

 a.advanced-statis-tics {
     color: #103D56;
     text-decoration: none;
 }

 .message-box h6 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;

     margin: 0;
 }

 .message-box p {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 21px;
     margin: 0;
     color: #a59f9f;
 }

 .message-box span {
     background: #103D5633;
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 600;
     color: #103D56;
     line-height: 15px;
     padding: 6px 10px;
     border-radius: 25px;
 }

 .message-box h3 {
     /* font-family: Poppins; */
     font-size: 10px;
     font-weight: 500;
     line-height: 15px;
     color: #a59f9f;
     margin: 0;
 }

 .message-left .heading {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
     margin-top: 15px;
 }

 .messages-total {
     height: 500px;
     overflow-y: scroll;
     padding: 3px;
 }

 .messages-total::-webkit-scrollbar {
     display: none;
 }

 .message-right {
     background: #F5F5F5;
     width: 100%;
     height: 100%;
     border-radius: 5px;
     padding: 15px;
 }

 .user-top-info {
     box-shadow: 0px 2px 20px 0px#103D5626;
     background: #fff;
     border-radius: 10px;
     padding: 10px 10px;
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .user-top-inner {
     display: flex;
     align-items: center;
     gap: 11px;
 }

 .user-top-inner img {
     width: 50px;
     height: 50px;
 }

 .user-top-inner h3 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 24px;
     margin: 0;
 }

 .user-top-inner p {
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 500;
     line-height: 18px;
     text-align: left;
     margin: 0;
 }

 .user-top-info .dropdown button {
     background: none;
     border: none;
     padding: 0;
 }

 .user-top-info .btn-check:checked+.btn,
 .btn.active,
 .btn.show,
 .btn:first-child:active,
 :not(.btn-check)+.btn:active {
     background-color: transparent !important;
 }

 .user-top-info .dropdown .dropdown-item {
     font-size: 13px;
 }

 .left-user {
     display: flex;
     gap: 15px;
     align-items: center;
     margin-bottom: 10px;
 }

 .left-user img {
     border-radius: 41px;
     width: 50px;
     height: 50px;
     object-fit: cover;
 }

 .left-user h4 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 12px;
     background: #fff;
     padding: 15px 20px;
     border-radius: 10px;
 }

 .left-user p {
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 400;
     line-height: 10px;
     color: #797C7B80;
     margin: 0;
     text-align: end;
 }

 .message-box-sec {
     height: 45vh;
     display: flex;
     overflow-y: scroll;
     flex-direction: column;
     margin-bottom: 16px;
     justify-content: end;
 }

 .message-box-sec::-webkit-scrollbar {
     display: none;
 }

 .message-send {
     display: flex;
     gap: 15px;
 }

 .message-send input.form-control {
     box-shadow: 0px 2px 20px 0px#103D5626;
     padding: 11px 16px;
     font-size: 14px;
     font-weight: 400;
     line-height: 21.82px;
     border: none;
 }

 .message-send button {
     box-shadow: 0px 2px 20px 0px#103D5626;
     background: #fff;
     border: none;
     padding: 10px 12px;
     border-radius: 7px;
 }

 .cmn-modal button.btn.btn-primary {
     background: #103D56;
     border: none;
     font-size: 14px;
     padding: 10px 84px;
 }

 .cmn-modal .modal-title {
     /* font-family: Poppins; */
     font-size: 20px;
     font-weight: 600;
     line-height: 37.5px;
     color: #103D56;
 }

 .cmn-modal button.btn-close {
     box-shadow: 0px 0px 10px 0px#103D564D;
     border-radius: 36px;
     width: 23px;
     height: 23px;
 }

 .cmn-modal .modal-content {
     padding: 15px;
 }

 .small-cmn-shadow {
     display: flex;
     gap: 10px;
     align-items: center;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 10px;
     border-radius: 10px;
     height: 50px;
 }

 .small-cmn-shadow h3 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     color: #000;
 }

 .small-cmn-shadow img {
     width: 30px;
     height: 30px;
     object-fit: cover;
     border-radius: 57px;
 }

 .small-cmn-shadow h4 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     color: #000;
 }

 .small-cmn-shadow input {
     width: 167px;
     border: none;
     background: none;
     font-size: 13px;
 }

 .small-cmn-shadow button {
     background: none;
     border: none;
 }

 .choose-agent h3 {
     /* font-family: Poppins; */
     font-size: 17px;
     font-weight: 400;
     line-height: 27px;
     text-align: center;
 }

 .choose-agent p {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     text-align: center;
     margin: 0;
 }


 .calendor-box .react-calendar {
     border: 2px solid#103D56;
     border-radius: 10px;
 }

 .meetings-main h3 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     line-height: 30px;
 }

 .meeting-box {
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 15px;
     border-radius: 10px;
     margin-bottom: 15px;
 }

 .meeting-box h5 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;
 }

 .meeting-box p {
     font-size: 11px;
     font-weight: 500;
     color: #00000080;
     line-height: 1.3;
     margin: 0;
 }


 .schedule-btn button {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 500;
     line-height: 27px;
     text-align: center;
     background: #103D56;
 }

 .schedule-btn button {
     /* font-family: Poppins; */
     font-size: 17px;
     font-weight: 400;
     line-height: 27px;
     text-align: center;
     background: #103D56;
     color: #fff;
     border: none;
     padding: 10px 120px;
     border-radius: 10px;
 }

 .profile-btns a img {
     width: 32px;
 }

 .social-media {
     display: flex;
     gap: 20px;
     margin-top: 20px;
 }

 .profile-card a {
     display: flex;
     justify-content: space-between;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 12px 20px;
     border-radius: 10px;
     text-decoration: none;
 }

 .profile-card a h3 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     color: #1D1617;
 }

 .profile-card button {
     display: flex;
     justify-content: space-between;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 12px 20px;
     border-radius: 10px;
     text-decoration: none;
     width: 100%;
     border: none;
     background: #fff;
     align-items: center;
 }

 .profile-card button h3 {
     margin: 0;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     color: #1D1617;
 }

 .profile-user .user-img {
     width: 100%;
     height: 190px;
     object-fit: cover;
     border-radius: 15px;
 }

 .message-box img {
     width: 60px;
     height: 60px;
     object-fit: cover;
     border-radius: 10px;
 }

 .player-box {
     display: flex;
     align-items: flex-start;
     gap: 12px;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 12px 13px;
     border-radius: 10px;
     margin-bottom: 13px;
 }

 .play-outer {
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 13px;
     border-radius: 10px;
 }

 .player-box img {
     width: 80px;
     height: 80px;
     border-radius: 10px;
     object-fit: cover;
 }

 .player-box h3 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;
     line-height: 18px;
     margin: 0;
 }

 .player-box h5 {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 500;
     color: #1D161780;
     margin-top: 3px;
 }

 .player-box p {
     /* font-family: Poppins; */
     font-size: 10px;
     font-weight: 600;
     line-height: 15px;
     text-align: left;
     margin: 0;
     color: #1D161780;
 }

 .player-box p span {
     /* font-family: Poppins; */
     font-size: 10px;
     font-weight: 600;
     line-height: 15px;
     text-align: left;
     margin: 0;

     color: #1D1617;

 }

 .player-box h3 span {
     color: #1D161780;
     font-weight: 500;
     font-size: 14px;
 }


 .subscription h2 {
     /* font-family: Poppins; */
     font-size: 20px;
     font-weight: 700;
     line-height: 30px;
 }


 .subscription h2 span {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;


 }

 .subscription p.text-center {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
     color: #000;
     margin-top: 15px;
 }

 .subscription ul li {
     display: flex;
     gap: 11px;
 }

 .subscription ul {
     padding: 0;
 }

 .subscription ul h3 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     color: #000;
 }

 .subscription h4 {
     color: #757575;
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24.04px;
     margin: 0;
 }

 .subscription ul li div {
     width: fit-content;
 }

 .subscription {
     padding: 0 40px;
 }

 .common-outer {
     box-shadow: 0px 2px 20px 0px #00239526;
     background: #FFFFFF;
     padding: 20px 20px;
     border-radius: 5px;
 }

 .image-upload-main h3 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     line-height: 30px;
 }

 .image-upload-inner {
     box-shadow: 0px 2px 20px 0px #00239526;
     width: 100%;
     height: 300px;
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     border-radius: 10px;
     margin-top: 15px;
     position: relative;
     cursor: pointer;
 }

 .image-upload-placeholder h4 {
     margin-top: 13px;
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
 }

 .image-upload-inner input[type="file"] {
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     opacity: 0;
     cursor: pointer;
     z-index: 1;
 }

 .image-upload-inner .prev-img {
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 10px;
 }

 .image-upload-main {
     position: relative;
 }

 .image-upload-inner button {
     background: none;
     border: none;
     position: absolute;
     right: 15px;
     z-index: 111;
     top: 11px;
 }

 .image-upload-inner.small {
     height: 215px;
 }

 .image-upload-inner.small .image-upload-placeholder img {
     width: 49px;
 }

 .video-link h3 {
     /* font-family: Poppins; */
     font-size: 18px;
     font-weight: 600;
     line-height: 30px;
 }

 .video-link input {
     border: 1px solid #c3bbbb;
     padding: 10px 12px;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     color: #DADADA;
 }

 .video-link small {
     /* font-family: Poppins; */
     font-size: 11px;
     font-weight: 400;
     line-height: 15px;
 }


 .cmn-light-btn button {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 400;
     line-height: 27px;
     text-align: center;
     background: #103D56;
     color: #fff;
     border: none;
     padding: 7px 67px;
     border-radius: 7px;
     max-width: 200px;
 }

 .msg-agent button {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 400;
     line-height: 27px;
     text-align: center;
     background: #103D56;
     color: #fff;
     border: none;
     padding: 10px 139px;
     border-radius: 7px;
 }

 .cmn-light-btn a {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 400;
     line-height: 27px;
     text-align: center;
     background: #103D56;
     color: #fff;
     border: none;
     padding: 7px 150px;
     border-radius: 7px;
     text-decoration: none;
 }

 .accord-tabs .nav.nav-tabs {
     display: flex;
     justify-content: space-between;
     border: none;
     gap: 20px;
     margin-bottom: 0 !important;
 }

 .accord-tabs li.nav-item .nav-link.active {
     border: none !important;
     color: #000;
     border-radius: 10px;
 }

 .accord-tabs .nav.nav-tabs li {

     width: calc(96% / 3);
     border-radius: 10px;
 }

 .accord-tabs li.nav-item button {
     box-shadow: 0px 2px 20px 0px #00239526;
     width: 100%;
     border-radius: 10px;
 }

 .accord-tabs .tab-content {
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 10px;
     border-radius: 0 0 10px 10px;
 }

 .accord-tabs .nav.nav-tabs li .nav-link.active {}


 .play-dropdown button {
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: space-between;
     box-shadow: 0px 2px 20px 0px #00239526;
     background: #fff;
     border: none;
     color: #000;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     padding: 11px 20px;
     line-height: 21px;
     background-image: url("../images/arrowdown.svg");
     background-repeat: no-repeat;
     background-position: center right 20px;
 }

 .play-dropdown button:hover {
     background-color: #fff;
     color: #000;
 }

 .play-dropdown .dropdown-toggle::after {
     display: none;
 }

 .play-dropdown .dropdown-menu.show {
     width: 100%;
     box-shadow: 0px 2px 20px 0px #00239526;
     border: none;
 }

 .play-dropdown .dropdown-menu.show a.dropdown-item {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .play-dropdown .btn.show {
     color: #000;
 }

 .select-file {
     background: #E9E9E9;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 10px 10px;
     position: relative;
     border-radius: 5px;
 }

 .select-file p {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     color: #000000;

     margin: 0;
 }

 .select-file input[type="file"] {
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     cursor: pointer;
 }

 .cmn-form-fields input {
     padding: 10px 10px;
     border: none;
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);


 }

 .common-shadow {
     box-shadow: 0px 2px 20px 0px #00239526;
     background: #FFFFFF;
     padding: 20px 20px;
     border-radius: 5px;
 }

 .common-shadow h3 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }



 .num-inputs {
     display: flex;
     gap: 75px;
     align-items: center;
 }

 .num-inputs input {
     border: 1px solid #000;
     box-shadow: none;


 }

 .phone-select {
     display: flex;
     align-items: center;
 }

 .num-inputs input::placeholder {
     color: #DADADA;

 }

 .image-upload-placeholder img.inner-view {
     position: absolute;
     left: 0;
     width: 100%;
     height: 100%;
     top: 0;
     border-radius: 10px;
     object-fit: cover;
 }

 .num-inputs input[type=number]::-webkit-inner-spin-button,
 input[type=number]::-webkit-outer-spin-button {
     appearance: auto;
 }

 .player-profile-main {
     height: 280px;
     border-radius: 10px;
     position: relative;
     z-index: 1;
 }

 .player-left-info {
     display: flex;
     align-items: end;
     gap: 15px;
 }

 .player-left-info .play-img {
     width: 230px;
     height: 235px;
     object-fit: cover;
 }


 .player-left-info h2 {
     font-family: 'Oswald';
     font-size: 28px;
     font-weight: 700;
     line-height: 36px;
     color: #FFFFFF;
 }

 .player-left-info p {
     font-family: 'Oswald';
     font-size: 18px;
     font-weight: 500;
     line-height: 24px;
     color: #fff;
     margin-right: 10px;
 }

 .player-left-info button {
     background: none;
     border: none;
 }

 .socio-icons {
     display: flex;
     gap: 15px;
     margin-top: 10px;
     margin-bottom: 35px;
 }

 .socio-icons a {
     background: #FFFFFF;
     border-radius: 20px;
     width: 25px;
     height: 25px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .socio-icons a img {
     width: 15px;
     height: 12px;
 }

 .player-profile-main::before {
     content: "";
     position: absolute;
     left: 0;
     right: 0;
     bottom: 0;
     border-radius: 10px;
     width: 100%;
     height: 100%;
     background: #2626266e;
     z-index: -1;
 }

 .play-edit a {
     color: #fff;
     text-decoration: none;
     display: flex;
     gap: 10px;
     align-items: center;
 }

 .play-info-box {
     display: flex;
     gap: 10px;
     border: 1px solid #fff;
     width: 417px;
     border-radius: 10px;
     padding: 14px;
     justify-content: space-between;
 }

 .play-info-box h3 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 400;
     line-height: 24px;
     text-align: center;
     color: #FFF;
     margin: 0px;
 }

 .play-info-box div {
     display: flex;
     flex-direction: column-reverse;
     align-items: center;
     justify-content: center;
     gap: 10px;
 }

 .play-info-box p {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 21px;
     text-align: center;
     color: #fff;
     margin: 0;
 }

 .player-right-info {
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     height: 100%;
     padding: 25px;
     align-items: end;
 }


 .search-cmn-box h2 {
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 600;
     line-height: 24px;
 }

 .search-inner-box ul {
     padding: 0;
     list-style: none;
     margin: 0;
 }

 .search-inner-box {
     /* border: 2px solid#103D56; */
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 0px;
     border-radius: 10px;
 }

 .search-inner-box ul li {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 14px 15px;
     border-radius: 0px;
     margin-bottom: 4px;
     border-bottom: 1px solid #DEDEDE;
     font-size: 13px !important;
 }

 .search-inner-box ul li h4 {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 500;
     line-height: 18px;
     margin: 0;
 }

 .search-inner-box ul li p {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 500;
     color: #140f1080;
     margin: 0;

 }

 .play-inner-box.search-result .badge-img img {
     width: 20px !important;
     height: 20px !important;
 }

 .play-inner-box.search-result .small-images {
     width: 30px;
     height: 30px;
     border-radius: 30px;
     margin: -6px;
 }

 .serviceslist_accord .accordion-header button p {
     margin-left: 20px;
 }

 .players-value.inner-tab {
     width: 90%;
 }

 .players-value.inner-tab h3 {
     font-size: 13px;
     line-height: 1.2;
 }

 .search-inner-box .in-heading {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
 }





 .play-search-box {
     display: flex;
     gap: 10px;
     border: 1px solid #fff;
     width: 100%;
     border-radius: 10px;

     justify-content: space-between;
 }

 .play-search-box h3 {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
     text-align: center;
     color: #000;
 }

 .play-search-box p {
     /* font-family: Poppins; */
     font-size: 13px;
     font-weight: 400;
     line-height: 21px;
     text-align: center;
     color: #1D161780;
     margin: 0;

 }

 .flag-img {
     position: absolute;
     width: 58px;
     /* box-shadow: 0px 2px 20px 0px #00239526; */
     /* background-color: #fff; */
     height: 46px;
     object-fit: contain;
     left: 0 !important;
     padding: 5px;
     border-radius: 8px 0 0 8px;
     top: 30px !important;
 }

 .city-select select {
     padding-left: 65px;
 }

 .user-profile .dropdown button {
     padding: 0;
     background: none;
 }

 .user-profile .dropdown button h3 {
     color: #fff;
     text-align: left;
 }

 .user-profile .dropdown button p {
     color: #879EAA;

 }

 .user-profile .dropdown .dropdown-item {
     font-size: 15px;
 }

 .user-profile .dropdown button::after {
     background-image: url("../images/arrowdown.svg");
     width: 18px;
     height: 15px;
     background-size: contain;
     background-repeat: no-repeat;
     display: none;
 }

 .agent-socio-icons {
     display: flex;
     gap: 20px;
     margin-right: 40px;
 }

 .form-control:focus {
     box-shadow: 0px 2px 20px 0px #00239526;
 }

 .main-section.height-set {
     height: 100%;
     padding: 50px 0;
     overflow-y: scroll;
 }

 .main-section.height-set::-webkit-scrollbar {
     display: none;
 }


 .form-flag .css-1dimb5e-singleValue {
     background-color: #fff;
     border: none;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 400;
 }

 .form-flag .css-13cymwt-control {
     box-shadow: 0px 2px 20px 0px #00239526;
     background-color: #fff;
     border: none;
 }

 .form-flag .css-t3ipsp-control {
     border-color: transparent !important;
     box-shadow: 0px 2px 20px 0px #00239526;
 }


 .gradient-sec {
     background: linear-gradient(91deg, #2d5fe5, #ef117e);
     padding: 95px 0;
 }

 .bottom-form-sec {
     padding: 30px 0;
     margin: 0 auto;
     max-width: 690px;
 }

 .bottom-form-sec h2 {
     margin: 0 auto 55px;
     max-width: 600px;
     color: #fff;
     font-size: 25px;
     text-align: center;
 }

 .form-detail {
     background: #fff;
     border: 1px solid #0a4949;
     border-radius: 6px;
     padding: 30px;
 }

 .form-detail input.form-control {
     background: #fff;
     border-bottom: 0.5px solid #000 !important;
     border: 0;
     border-radius: 0;
     font-size: 13px;
     color: #000;
     line-height: 22.857px;
     padding: 8px 0;
 }

 .form-detail input.form-control::placeholder {
     color: #000;
 }

 .form-detail h3 {
     font-size: 18px;
     line-height: 25px;
 }

 .form-detail input.form-control:focus {
     box-shadow: none;
 }

 .form-detail input.form-control {
     background: #fff;
     border-bottom: 0.5px solid #000 !important;
     border: 0;
     border-radius: 0;
     font-size: 13px;
     color: #000;
     line-height: 22.857px;
     padding: 8px 0;
 }

 .form-detail input.form-control::placeholder {
     color: #000;
 }

 .form-detail h3 {
     font-size: 18px;
     line-height: 25px;
 }

 .formik-errors {
     color: red;
     font-size: 12px;
 }

 .register-btn button {
     background: #2D5FE5;
     border: none;
     color: #fff;
     font-size: 15px;
     font-weight: 500;
     letter-spacing: -.3px;
     line-height: 20px;
     padding: 15px;
     width: 300px;
 }

 .terms_check {
     padding: 20px 120px;
 }

 .terms_check span {
     color: #000;
     font-size: 13px;
 }

 .terms_check a {
     color: #000;
 }

 .terms_check .form-check input {
     border: 1px solid #aeb5b5;
 }

 button.lg-btn-disabled {
     cursor: not-allowed;
     background: #aeb5b5;
 }

 .main-background-image {
     background-image: url("../images/HOMEBANNER.png");
     width: 100%;
     z-index: 1;
     position: relative;
     background-size: cover;
     transition: background-position 0.1s;
 }

 .main-background-image .fist-slide {
     height: 100vh !important;
     display: flex !important;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }

 .main-background-image .fist-slide {
     height: 100vh !important;
     padding-top: 100px;
 }

 .main-background-image .second-slide {
     height: 100vh !important;
     padding: 200px 0;
 }

 .main-background-image .third-slide {
     height: 100vh !important;
     padding: 200px 0;

 }

 /* .main-background-image .third-slide::before {
      content: "";
    background-color: #0000006b;
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: fixed;
} */
 .fist-slide h3 {
     font-family: Raleway;
     font-size: 50px;
     font-weight: 500;
     line-height: 115px;
     color: #FFFFFF;
     margin-top: 50px;
 }

 .main-background-image .fist-slide p {
     font-family: Futura;
     font-size: 25px;
     font-weight: 300;
     color: #fff;
     line-height: 41.37px;
     margin-left: 50px;
     margin-top: 100px;
     letter-spacing: 0.45em;
     text-align: left;
 }

 @keyframes blink {
     50% {
         color: transparent
     }
 }

 .loader__dot {
     animation: 1s blink infinite
 }

 .loader__dot:nth-child(2) {
     animation-delay: 250ms
 }

 .loader__dot:nth-child(3) {
     animation-delay: 500ms
 }


 .main-background-image .second-slide {
     height: 100vh !important;
     display: flex !important;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }

 .second-slide h3 {
     font-family: Raleway;
     font-size: 35px;
     font-weight: 400;
     line-height: 52.83px;
     text-align: left;
     color: #FFFFFF;
     margin-bottom: 25px;
 }

 .second-slide p {
     font-family: Raleway;
     font-size: 17px;
     font-weight: 200;
     line-height: 23.48px;
     text-align: left;
     color: #fff;
     width: 590px;
     margin-bottom: 56px;
 }

 .second-slide h4 {
     font-family: Raleway;
     font-size: 34px;
     font-weight: 400;
     line-height: 46.96px;
     text-align: left;
     color: #fff;
 }

 .second-slide h4 a {
     color: #fff;
 }

 .main-background-image .third-slide {
     height: 100vh !important;
     display: flex !important;
     align-items: center;
     justify-content: center;
     flex-direction: column;
 }

 .third-slide h3 {
     font-family: Raleway;
     font-size: 20px;
     font-weight: 400;
     line-height: 35.22px;
     text-align: left;
     color: #fff;
 }

 .third-slide .top-h {
     margin-top: 40px;
 }

 .third-form label.form-label {
     font-family: Raleway;
     font-size: 15px;
     font-weight: 400;
     line-height: 29.35px;
     text-align: left;
     color: #fff;
     margin: 0;
 }

 .third-form input.form-control {
     border: 2px solid #ffffff47;
     background: transparent;
     color: #D6D6D6;
     border-radius: 0;
     width: 350px;
     height: 45px;
     font-size: 13px;
 }

 .third-form input.form-control::placeholder {
     font-size: 13px;

     color: #D6D6D6;


 }

 .third-form button {
     width: 186px;
     border-radius: 5px;
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
     text-align: left;
     background: #103D56;
     border: none;
     display: flex;
     justify-content: center;
     align-items: center;
     height: 45px;
     margin-top: 28px;
 }

 .third-form {
     margin-top: 70px;
 }

 .main-background-image .slick-dots {
     position: fixed !important;
     right: 0 !important;
     top: 0 !important;
     width: 200px;
     bottom: 0 !important;
     transform: rotate(90deg);
     display: flex !important;
     align-items: center;
     --animate-delay: 5s;
     --animate-duration: 2000ms;
     --animation-name: fadeInUp;

 }


 .main-background-image .slick-dots li button:before {

     width: 21px;
     border-radius: 37px;
     border: 2px solid #fff;
     height: 21px;
     opacity: 1;

 }

 .main-background-image .slick-dots li {
     width: 21px;

     border-color: #fff;
     height: 21px;
 }

 .main-background-image .slick-dots .slick-active button:before {
     color: #fff;
     width: 21px;
     border-radius: 37px;
     height: 21px;
     opacity: 1;
     padding: 0px 0px 0px 0px;
     font-size: 15px;
 }

 .main-background-image .slick-arrow.slick-prev {
     position: fixed;
     top: 5px;
     left: 0;
     right: 0;
     z-index: 11;
     width: 60px;
     visibility: visible;
     opacity: 1;
     transition: 0.5s ease;
     margin: auto;
 }

 .main-background-image .slick-arrow.slick-next {
     position: fixed;
     bottom: 103px !important;
     left: 0;
     z-index: 11;
     top: unset !important;
     width: 60px;
     right: 0;
     margin: auto;
 }

 .main-background-image .slick-arrow.slick-prev.slick-disabled {
     visibility: hidden;
     opacity: 0;
 }

 .main-background-image .slick-arrow.slick-next.slick-disabled {
     display: none !important;
 }

 .main-background-image .slick-next:before {
     content: "";
     background-image: url("../images/slidedown.svg");
     width: 60px;
     display: block;
     height: 60px;
     background-repeat: no-repeat;
     border: 3px solid #fff;
     background-position: center;
     transition: all .2s ease 0s;
     -webkit-transition: all 150ms cubic-bezier(1, 0, 0, 1);
     transition: all 150ms cubic-bezier(1, 0, 0, 1);
     opacity: 1;
     animation: fadeInUp;
     webkit-transition: all .2s ease 0s;
     transition: all .2s ease 0s;
     animation-duration: 1s;
     border-radius: 5px 5px 5px 5px;
 }

 .main-background-image .slick-next:after {
     top: -95px !important;
     opacity: 0 !important;
     transform: 0.2s ease;
 }

 .main-background-image .slick-next:hover:after {
     top: -60px !important;
     opacity: 1 !important;
 }

 .main-background-image .slick-next:hover:before {
     top: 10px;
     background-position: 20px 73px;
 }

 .main-background-image .slick-next:after {
     content: "";
     background-image: url("../images/slidedown.svg");
     width: 60px;
     display: block;
     height: 60px;
     background-repeat: no-repeat;
     top: -112px;
     position: relative;
     webkit-transition: all .6s ease 0s;
     transition: all .6s ease 0s;
     background-position: center;
     transition: all .6s ease 0s;
     -webkit-transition: all 150ms cubic-bezier(1, 0, 0, 1);
     transition: all 150ms cubic-bezier(1, 0, 0, 1);
     opacity: 1;
     animation: fadeInUp;
     animation-duration: 2s;

 }

 .overlay-open {
     position: absolute;
     z-index: -1;
     top: 0;
     opacity: 0;
     visibility: hidden;


     overflow: hidden;
     width: 100%;
     height: 100%;
     -webkit-transition: all .8s ease;
     transition: all .8s ease;
     pointer-events: none;
     background: rgba(0, 0, 0, .8);
 }

 .overlay-open.active {
     opacity: 1;
     visibility: visible;
 }

 /* .main-background-image .slick-next:after {
content: "";
background-image: url("../images/slidedown.svg");
width: 60px;
    display: block;
    height: 60px;
    transition: all .6s ease 0s;
    background-repeat: no-repeat;
    transition: all 150ms cubic-bezier(1,0,0,1);
    background-position: center;
    opacity: 1;
    position: absolute;
    top: -100px;
} */
 .main-background-image .slick-prev:before {
     content: "";
     background-image: url("../images/slideup.svg");
     width: 60px;
     display: block;
     height: 60px;
     background-color: #fff;
     opacity: 1;
     background-repeat: no-repeat;
     border: 2px solid #fff;
     background-position: center;
     animation: fadeInUp;
     animation-duration: 2s;
     border-radius: 0 0 5px 5px;
 }

 .main-background-image .slick-prev:after {
     content: "";
     background-image: url("../images/slideup.svg");
     width: 60px;
     display: block;
     height: 60px;

     opacity: 1;
     background-repeat: no-repeat;

     background-position: center;
     animation: fadeInUp;
     animation-duration: 2s;
 }

 .main-background-image .slick-prev:after {
     top: -18px !important;
     opacity: 0 !important;
     transition: 0.2s ease;
     position: relative;

 }

 .main-background-image .slick-prev:hover:after {
     top: -60px !important;
     opacity: 1 !important;
     position: relative;

 }

 .main-background-image .slick-prev:hover:before {
     top: 10px;
     background-position: 20px 73px;
     transform: 0.2s ease;

 }




 :root {
     --animate-duration: 2000ms;

 }

 .animate__fadeInUp {
     --animate-delay: 5s;
     --animate-duration: 1000ms;
 }

 .page-loader {
     position: fixed;
     z-index: 99999;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: #111;
 }

 .page-loader .spinner-container {
     position: absolute;
     z-index: 200;
     left: 50%;
     top: 50%;
     -webkit-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
 }

 .page-loader .spinner-container .css-spinner {

     display: block;
     width: 36px;
     height: 36px;
     -webkit-transition: all .4s cubic-bezier(.19, 1, .22, 1);
     transition: all .4s cubic-bezier(.19, 1, .22, 1);
     -webkit-animation: spinner .4s linear infinite;
     animation: spinner .4s linear infinite;
     opacity: 1;
     border: solid 2px transparent;
     border-top-color: #1fb8b2;
     border-left-color: #1fb8b2;
     border-radius: 100%;
 }

 @-webkit-keyframes spinner {
     0% {
         -webkit-transform: rotate(0);
         transform: rotate(0)
     }

     100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg)
     }
 }

 @keyframes spinner {
     0% {
         -webkit-transform: rotate(0);
         transform: rotate(0)
     }

     100% {
         -webkit-transform: rotate(360deg);
         transform: rotate(360deg)
     }
 }

 .page-loader.hide-this {
     bottom: 100%;
     -webkit-transition: all .8s cubic-bezier(.54, .086, 0, .98) .2s;
     transition: all .8s cubic-bezier(.54, .086, 0, .98) .2s;
 }

 @keyframes fadeInDDown {
     0% {
         opacity: 0;
         transform: translateY(-20px);
     }

     100% {
         opacity: 1;
         transform: translateY(0);
     }
 }



 @keyframes fadeInMiddle {
     from {
         opacity: 0;
         transform: translateY(20%);
     }

     to {
         opacity: 1;
         transform: translateY(0);
     }
 }

 .animate__fadeInMiddle {
     animation-name: fadeInMiddle;
 }


 /* Define keyframes for the animations */
 @keyframes slideInFromTop {
     0% {
         transform: translateY(-100%);
         opacity: 0;
     }

     100% {
         transform: translateY(0);
         opacity: 1;
     }
 }

 @keyframes slideInFromBottom {
     0% {
         transform: translateY(100%);
         opacity: 0;
     }

     100% {
         transform: translateY(0);
         opacity: 1;
     }
 }

 /* Apply animations to specific elements with delay */
 .fist-slide img {
     animation: slideInFromTop 1s ease-out;
     animation-delay: 0.8s;
     /* Delay before starting the image animation */
     width: 400px;
 }

 .fist-slide h3,
 .fist-slide p {
     animation: slideInFromBottom 1s ease-out;
     animation-delay: 0.8s;
     /* Delay before starting the text animations */
 }

 /* Optional: Add delay for individual text elements if needed */
 .fist-slide p {
     animation-delay: 0.8s;
     /* Additional delay for paragraph */
 }

 .loader__dot {
     display: inline-block;
     animation: blink 1s infinite;
 }

 @keyframes blink {

     0%,
     100% {
         opacity: 0;
     }

     50% {
         opacity: 1;
     }
 }



 .profile-card .css-b62m3t-container {
     padding: 1px 1px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .profile-card .css-b62m3t-container .css-13cymwt-control {
     border: none;
     box-shadow: none;
     border-radius: 10px;
 }

 .profile-card .css-b62m3t-container .css-t3ipsp-control {
     border-color: transparent;
     box-shadow: none;

 }

 .profile-card .css-b62m3t-container .css-1dimb5e-singleValue {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .profile-card .css-b62m3t-container div#react-select-3-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .profile-card .css-b62m3t-container .css-1jqq78o-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .profile-card .css-b62m3t-container .css-1jqq78o-placeholder {
     grid-area: 1 / 1 / 2 / 3;
     margin-left: 2px;
     margin-right: 2px;
     box-sizing: border-box;
     color: #1D1617;
 }






 .cmn-form-fields .css-b62m3t-container {
     /* box-shadow: 0px 2px 20px 0px #00239526; */
     border-radius: 7px;
     padding: 1px 1px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .cmn-form-fields .css-b62m3t-container .css-13cymwt-control {
     border: none;
     box-shadow: none;
     border-radius: 10px;
 }

 .cmn-form-fields .css-b62m3t-container .css-t3ipsp-control {
     border-color: transparent;
     box-shadow: none;

 }

 .cmn-form-fields .css-b62m3t-container .css-1dimb5e-singleValue {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     text-align: left;
 }

 .cmn-form-fields .css-b62m3t-container div#react-select-3-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     text-align: left;
 }

 .cmn-form-fields .css-b62m3t-container .css-1jqq78o-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 21px;
     text-align: left;
 }

 .cmn-form-fields .css-b62m3t-container .css-1jqq78o-placeholder {
     grid-area: 1 / 1 / 2 / 3;
     color: #000;
     margin-left: 2px;
     margin-right: 2px;
     box-sizing: border-box;
 }





 .form-inner .css-b62m3t-container {
     padding: 1px 1px;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .form-inner .css-b62m3t-container .css-13cymwt-control {
     border: none;
     box-shadow: none;
     border-radius: 10px;
 }

 .form-inner .css-b62m3t-container .css-t3ipsp-control {
     border-color: transparent;
     box-shadow: none;

 }

 .form-inner .css-b62m3t-container .css-1dimb5e-singleValue {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .form-inner .css-b62m3t-container div#react-select-3-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .form-inner .css-b62m3t-container .css-1jqq78o-placeholder {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
 }

 .form-inner .css-b62m3t-container .css-1jqq78o-placeholder {
     grid-area: 1 / 1 / 2 / 3;
     color: #000;
     margin-left: 2px;
     margin-right: 2px;
     box-sizing: border-box;
 }

 .third-form label.form-label span {
     font-family: Raleway;
     font-size: 12px;
     font-weight: 500;
     line-height: 23.48px;
     margin-left: 4px;
 }

 .image-upload-in {
     width: 200px;
     position: relative;
     height: 140px;
 }

 .image-upload-in input {
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     opacity: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
 }

 .image-upload-in .user-img {
     width: 100%;
     height: 100%;
     object-fit: cover;
 }

 .phone-select .react-select-set.css-b62m3t-container {
     width: 66px;
     box-shadow: none;
     position: absolute;
     left: 0;
     top: 31px;
 }

 .phone-select input.form-control {
     padding-left: 74px;
 }

 .phone-select .css-hlgwow {
     padding: 0px 5px;
     height: 40px;
 }

 .outer-num-input input.form-control {
     border: 1px solid #000;
     box-shadow: none;
     appearance: auto !important;
 }

 .num-inputs.outer {
     display: block;
 }

 .num-inputs.outer input {
     width: 100% !important;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .profile-card .css-1dyz3mf {
     height: 40px;
     overflow-y: scroll;
 }

 .notify-sec .heart-img {
     width: 24px;
 }

 .play-inner-box .d-flex>img {
     width: 55px;
     height: 55px;
     object-fit: cover;
     border-radius: 10px;
 }

 .player-upload-div .delete-button {
     position: absolute;
     right: 15px;
     z-index: 11;
     top: 15px;
     background: none;
     border: none;
 }

 .ad-delt-btn .delete-button {
     position: absolute;
     right: 5px;
     z-index: 999;
     top: 5px;
     background: #fffd;
     border: 0;
 }

 .name-fix {
     align-items: center;
     justify-content: center;
     display: flex;
     margin-top: 26px;
     font-size: 16px;
 }

 .player-left-info .play-img {
     width: 230px;
     height: 235px;
     object-fit: cover;
     border: 2px solid#103D56;
     box-shadow: 0px 2px 20px 0px #00239526;
     border-bottom: 0;
     border-top-right-radius: 15px;
     border-top-left-radius: 15px;
     border: 0;
 }


 .ad-pop-height-player {
     height: 400px;
     overflow-x: hidden;
     overflow-y: scroll;
     scrollbar-width: thin;
 }

 .nodata {
     display: flex;
     justify-content: center;
     align-items: center;
 }



 .tab-content .accordion-header button {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 500;
     line-height: 21px;
     text-align: left;
     border-radius: 20px !important;
 }

 .tab-content .accordion-button:not(.collapsed) {
     background-color: transparent;
     box-shadow: none;
 }

 .tab-content .accordion-button:focus {
     box-shadow: none;
 }

 .tab-content .accordion-item {
     box-shadow: 0px 2px 20px 0px #00239526;
     margin: 10px 0;
     border-radius: 20px;
 }

 .tab-content .accordion-header button::after {
     width: 20px !important;
     height: 20px !important;
     background-size: 18px;
 }

 .inner-menu a {
     text-decoration: none;
 }



 .decided-card {
     height: 361px;
 }

 .no-data-found {
     height: 355px;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .decided-card p {
     margin: 0;
     font-size: 22px;
 }

 .terms-conditions h2 {
     font-size: 22px;
 }

 .terms-conditions p {
     font-size: 14px;
 }

 .terms-conditions {
     height: 361px;
 }

 .nodatafound {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
 }

 .main-section.height-set .css-ascpo7-MuiStepLabel-root {
     display: flex;
     flex-direction: column;
     gap: 10px;
 }

 .main-section.height-set .css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root {
     width: 45px;
     height: 45px;

 }

 .main-section.height-set .css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root text {
     font-size: 9px;
 }

 .main-section.height-set .css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root {
     width: 45px;
     height: 45px;
     font-size: 10px !important;
     color: transparent;
     border: 2px solid #C8C8C8;
     background: #fff;
     border-radius: 40px;
 }

 .main-section.height-set .css-1vyamtt-MuiStepLabel-labelContainer {
     color: #C8C8C8;
 }

 .main-section.height-set .css-1hv8oq8-MuiStepLabel-label.Mui-active {
     color: #103D56;
 }

 .main-section.height-set .css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {

     background: linear-gradient(90deg, #8BB9D6 12.5%, #103D56 87.5%), #002395;
     border: none;
 }

 .main-section.height-set .css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-active text {
     fill: #fff;
 }

 .main-section.height-set .css-117w1su-MuiStepIcon-text {
     fill: #C8C8C8;
 }

 .main-section.height-set .css-1hv8oq8-MuiStepLabel-label {
     font-family: "Poppins", sans-serif !important;
 }

 .main-section.height-set .css-1qv8q39-MuiStepConnector-line {
     border-color: #C8C8C8;
     border-top-width: 2px;
     margin-top: 20px;
 }

 .main-section.height-set .image-upload-placeholder svg {

     font-size: 45px;
 }

 .main-section.height-set #next-btn {
     background: #103D56;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 27px;
     padding: 10px 40px;
     text-align: center;
     border-radius: 10px;
 }

 .main-section.height-set #previous-btn {

     background: #103D56;
     /* font-family: Poppins; */
     font-size: 15px;
     font-weight: 500;
     line-height: 27px;
     padding: 10px 40px;
     text-align: center;
     border-radius: 10px;
     color: #fff;
 }

 .main-section.height-set .MuiContainer-root.MuiContainer-maxWidthLg.css-9wvnva .Mui-disabled {
     visibility: hidden;
     opacity: 0;
 }

 .main-section.height-set .css-19477v8-MuiStepper-root {
     align-items: start;
     position: relative;
     justify-content: space-between;
 }

 .main-section.height-set .css-j5w0w9-MuiStepConnector-root {
     position: absolute;
     left: 0;
     right: 0;
     z-index: -1;
     margin: auto;
     width: calc(100% - 180px);
 }

 .main-section.height-set button.Mui-disabled.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.css-142372x-MuiButtonBase-root-MuiButton-root {
     opacity: 0;
     visibility: hidden;
 }

 .main-section.height-set .css-nd0xiu-MuiStep-root {
     padding: 0;
 }


 .main-section.height-set .MuiStep-root.MuiStep-horizontal.Mui-completed.css-nd0xiu-MuiStep-root .Mui-completed svg {

     border: none;
     color: unset;
     fill: #103D56;
 }

 .main-section.height-set .MuiStep-root.MuiStep-horizontal.Mui-completed.css-nd0xiu-MuiStep-root .MuiStepLabel-labelContainer.css-1vyamtt-MuiStepLabel-labelContainer span {
     color: #103D56;
 }

 .form-inner .css-b62m3t-container .css-13cymwt-control .css-1wy0on6 {
     display: none;
 }

 .form-inner .css-b62m3t-container .css-t3ipsp-control .css-1wy0on6 {
     display: none;
 }

 .phone-select span.hyphen-set {
     position: absolute;
     left: 54px;
     color: #D6D6D6;

 }

 .cmn-form-fields input {}


 .login-right-head .dropdown-menu.show {
     transform: translate(28px, 5px) !important;
 }

 .steps-formn ul {
     list-style: none;
     padding: 0;
     display: flex;
     justify-content: space-between;
     z-index: 11;
     position: relative;
 }

 .steps-formn {
     position: relative;
 }

 .steps-formn ul li {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 500;
     line-height: 24px;
     color: #C8C8C8;
     text-align: center;
 }

 .steps-formn ul li span {
     border: 2px solid #C8C8C8;
     width: 45px;
     display: flex;
     margin: auto;
     background: #fff;
     height: 45px;
     justify-content: center;
     align-items: center;
     border-radius: 37px;
     margin-bottom: 15px;
 }

 .steps-formn .progress-set {
     position: absolute;
     left: 0;
     right: 0;
     top: 20px;
     width: calc(100% - 140px);
     margin: auto;
 }

 .steps-formn .progress-set span {
     display: none !important;
 }

 .steps-formn .progress-set>div>div {
     background: #103D56 !important;

 }

 .steps-formn ul .active-tab span {
     color: #fff;
     border: none;
     background: linear-gradient(90deg, #8BB9D6 12.5%, #103D56 87.5%), #002395;
 }

 .steps-formn ul .active-tab p {
     color: #103D56;
 }

 .steps-formn ul .active-tab span {
     color: #fff;
 }

 .pass-show img {
     width: 16px;
     height: 13px;
 }

 .inner-header .navbar-toggler:focus {
     box-shadow: none;
 }

 .common-search-field {
     display: flex;
     justify-content: space-between;
     width: 100%;
     gap: 20px;
     align-items: center;
 }


 .page-not-found {
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .header-btm-football {
     display: flex;
     justify-content: center;
 }

 .header-btm-football img {
     cursor: pointer;
     width: 80px;
 }

 .header-bottom-main {
     position: absolute;
     left: 0;
     right: 0;
     top: 69px;
     width: 69%;
     margin: auto;
     background: #ffffff00;
     transition: 0.5s ease;
     z-index: 1;
 }

 .header-bottom-main.hovered {
     top: 120px;
 }


 @keyframes topBottom {

     0%,
     100% {
         transform: translateY(0);
     }

     50% {
         transform: translateY(5px);
     }
 }

 .top-bottom-animation {
     animation: topBottom 2s ease-in-out infinite;
 }

 .stop-animation {
     animation: none;
 }


 .player-upload-div label img {
     width: 55px;
     margin: auto;
     display: table;
 }


 .player-profile-main.team .socio-icons a {
     background: none;
 }

 .player-profile-main.team .socio-icons a img {
     width: 30px;
     height: 30px;
 }

 .player-profile-main.team {
     height: 200px;
 }

 .player-profile-main.team .player-left-info .play-img {
     width: 200px;
     height: 180px;
     object-fit: cover;
     border-radius: 15px;
 }

 .player-profile-main.team .player-left-info {
     padding-left: 10px;
 }

 .team-players .play-inner-box {
     background: #F6F6F6;
     box-shadow: none;
 }

 .team-players h4 {
     /* font-family: Poppins; */
     font-size: 16px;
     font-weight: 600;
     line-height: 24px;
     color: #000000;
 }

 .team-players .linkclass {
     /* font-family: Poppins; */
     font-size: 14px;
     font-weight: 400;
     line-height: 18px;
     text-align: left;
     color: #7D7D7D;
     text-decoration: none;
 }

 .team-players .play-inner-box h5 {
     /* font-family: Poppins; */
     font-size: 12px;
     font-weight: 600;
     line-height: 18px;
     color: #1D161780;
 }

 .team-players .play-scroll-box .play-inner-box {
     align-items: start;
 }


 .team-players .play-inner-box p {
     color: #1D161780;
 }

 .team-players .play-inner-box p span {
     color: #1D1617;
 }

 .team-players .play-inner-box p {
     color: #1D1617;
 }

 .team-players .play-inner-box p span {
     color: #7D7D7D;

 }

 .team-players .play-scroll-box {
     height: 433px;
     overflow: unset;
 }

 .team-players .play-scroll-box .play-inner-box {
     align-items: start;
     height: 85px;
 }

 .team-players .play-scroll-box .play-inner-box img {
     width: 58px;
     height: 63px;
 }

 .compare-players-bottom:nth-child(odd) {
     background: #103D56;
     border-radius: 9px;
 }


 .card-heading.compare-team {
     height: 40px;
 }

 .agent-box {
     display: flex;
     gap: 11px;

 }

 .new-play-box {
     justify-content: space-between;
 }

 .home-box {
     justify-content: space-between !important;
     display: flex;
     width: 100%;
 }

 .home-box p:nth-child(2) {
     text-align: right;
 }

 h4.label-box {
     text-align: center;
     width: 100%;
 }

 /* calander css */

 .new-heading-color h2 {
     color: #000000;
 }

 .table-calander table thead tr th {
     font-size: 16px;
     font-weight: 500;
     text-align: center;
     line-height: 24px;
     background: #F6F6F6;
     padding: 14px 0px;
     color: #5E595A;
     /* font-family: "Poppins",
                sans-serif; */
     border-bottom: none;
 }

 .table-calander table thead tr {
     border-bottom: none;
 }

 .table-calander table tbody tr td {
     font-size: 12px;
     font-weight: 500;
     line-height: 15px;
     color: #615C5D;
     text-align: center;
     padding: 14px 2px;
     max-width: 180px;
 }

 .table-calander table tbody tr td img {
     margin-right: 8px;
 }

 .table-calander table tbody tr td a {
     color: #103D56;
 }

 .spacing-add {
     padding-left: 20px;
     padding-right: 20px;
 }

 .foot-images {
     display: flex;
     align-items: center;
     gap: 12px;
 }


 .fot-title h3 {
     font-size: 16px;
     font-weight: 600;
     line-height: 27px;
     color: #ffffff;
     /* font-family: "Poppins",
                sans-serif; */
 }

 .footer-links ul {
     margin: 0px;
     padding: 0px;
     text-decoration: none;
     list-style: none;
 }

 .footer-links ul li a {
     font-size: 14px;
     font-weight: 400;
     line-height: 24px;
     color: #DFDFDF;
     text-decoration: none;
 }

 .bottom-sec {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-top: 28px;
 }

 .bottom-sec {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-top: 21px;
     border-top: 1px solid #fff;
     padding: 13px 0px;
 }

 .app-store {
     display: flex;
     gap: 25px;
 }

 .footer-outer-bg {
     background: #103D56;
     d: #103D56;
 }

 /*  */

 /* Home animation  */
 .soccer {
     background: url(http://upload.wikimedia.org/wikipedia/en/e/ec/Soccer_ball.svg);
     background-size: 100px 100px;
     height: 100px;
     width: 100px;
     position: absolute;
     -webkit-animation: roll 4.5s infinite;
     -moz-animation: roll 4.5s infinite;
     animation: roll 4.5s infinite;
     top: 435px;
     left: 0;
     right: 350px;
     margin: auto;
 }




 @-moz-keyframes roll {
     0% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }

     50% {
         left: 700px;
         -webkit-transform: rotate(360deg);
     }

     100% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }
 }

 @-webkit-keyframes roll {
     0% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }

     50% {
         left: 700px;
         -webkit-transform: rotate(360deg);
     }

     100% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }
 }

 @keyframes roll {
     0% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }

     50% {
         left: 700px;
         -webkit-transform: rotate(360deg);
     }

     100% {
         left: 0px;
         -webkit-transform: rotate(-360deg);
     }
 }

 .world-cup {
     color: #82AE6F;
     text-align: center;
 }


 .upcoming-page h1 {
     /* font-family: Poppins; */
     font-size: 70px;
     font-weight: 600;
     margin: 0;
     color: #103D56;
     text-align: center;
 }

 .upcoming-page p {
     text-align: center;
     margin-top: 12px;
     font-size: 16px;
     font-weight: 400;
 }

 .home-animation-box {
     height: 480px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 /*  */

 thead tr th:first-child {
     border-radius: 10px 0px 0px 10px;
 }

 thead tr th:last-child {
     border-radius: 0px 10px 10px 0px;
 }

 .calendar-show {
     position: absolute;
     right: 29px;
     top: 0px;
 }

 .calendar-icons {
     position: relative;
 }

 .calendar-show .react-datepicker__month-container {
     border: 2px solid#103D56;
     border-radius: 4px;
 }

 .calendar-show .react-datepicker__day-names {
     white-space: nowrap;
     margin-bottom: -8px;
     border-top: 1px solid #D3E3F1;
     margin-top: 7px;
     background: #fff;
     border-bottom: 1px solid #D3E3F1;
 }


 .play-inner img.play-img {
     position: absolute;
     width: 100%;
     height: 100%;
     border-radius: 76px;
     background: #000;

 }

 .calendar-icons img {
     cursor: pointer;
 }

 .calendar-icons .react-datepicker__header {
     border-bottom: none;
     background: transparent;
 }

 .calendar-icons .react-datepicker__navigation-icon--next::before {
     top: 10px;
 }

 .calendar-icons .react-datepicker__navigation-icon--previous::before {
     top: 10px;
 }

 /* end */

 .under-dev h3 {
     /* font-family: Poppins; */
     font-size: 40px;
     font-weight: 600;
     margin: 0;
     color: #103D56;
     text-align: center;
 }

 .under-dev {
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .new-heading-color h2 {
     color: #000000;
     font-size: 18px;
 }

 .meeting-sheduled {
     display: flex;
     height: 28vh;
     align-items: center;
     justify-content: center;
 }

 .table-calander {
     height: 45vh;
     overflow-y: auto;
     overflow-x: auto;
     scrollbar-width: thin;
 }

 .loader_page {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100vh;
     background: #ffffff47;
     backdrop-filter: blur(3px);
     display: flex;
     align-items: center;
     justify-content: center;
     overflow: hidden;
     z-index: 9;
     flex-direction: column;
 }

 .loader_page span {
     right: -14px;
 }

 .comp-vs p {
     background: #103D56;
     /* padding: 11px; */
     border-radius: 52%;
     color: #fff;
     height: 52px;
     width: 52px;
     line-height: 54px;
     text-align: center;
     font-size: 18px;
 }

 .compare-players-bottom:nth-child(even) h4 {
     color: #103D56;
 }

 .compare-players-bottom:nth-child(even) p {
     color: #103D56;
 }


 /*    remove thiss */


 .upload-section {
     flex: 1;
     padding: 10px;
     border-right: 2px solid #ddd;
 }

 .upload-section h3 {
     margin-bottom: 10px;
 }

 .upload-section input {
     display: block;
 }

 .preview-section {
     flex: 2;
     padding: 10px;
 }

 .image-previews {
     display: grid;
     flex-wrap: wrap;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
     gap: 3px;
 }

 .image-thumbnail {
     position: relative;
     cursor: pointer;
 }

 .image-thumbnail img {
     width: 70px;
     height: 70px;
     object-fit: cover;
     border-radius: 5px;
     margin: auto;
 }

 .remove-button {
     position: absolute;
     top: -3px;
     right: -1px;
     top: 0;
     right: 0;
     background: rgba(0, 0, 0, 0.5);
     color: #fff;
     border: none;
     border-radius: 50%;
     width: 11px;
     height: 10px;
     text-align: center;
     line-height: 5px;
     cursor: pointer;
 }

 .remove-button:hover {
     background: rgba(0, 0, 0, 0.7);
 }

 .player-up h3 {
     font-size: 20px;
     font-weight: 600;
     line-height: 29px;
     color: #000;
     margin-bottom: 10px;
 }

 .uplode-the-img {
     padding: 10px 10px;
     border: none;
     display: flex;
     align-items: center;
     justify-content: space-between;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);

 }

 .click-to-up button {
     font-size: 16px;
     font-weight: 400;
     color: #fff;
     line-height: 23px;
     background: #103D56;
     padding: 5px 27px;
     border-radius: 10px;
     border: none;
     position: relative;
 }

 .click-to-up button input {
     position: absolute;
     width: 100%;
     height: 100%;
     left: 0;
     top: 0;
     opacity: 0;
     cursor: pointer;
 }

 .upload-and-drop {
     display: flex;
     align-items: center;
     gap: 22px;
 }

 .drag-drop p {
     font-size: 16px;
     font-weight: 400;
     line-height: 23px;
     margin: 0px;
     color: #BEBEBE;
 }

 .preview p {
     font-size: 16px;
     font-weight: 400;
     line-height: 23px;
     margin: 0px;
     color: #BEBEBE;
     text-decoration: underline;
 }

 .click-to-up {
     position: relative;
 }

 .click-to-up:before {
     position: absolute;
     content: "";
     width: 1px;
     height: 34px;
     background: #D5D5D5;
     right: -12px;
     opacity: 0.7;
 }

 .drag-drop p span {
     margin-right: 11px;
 }

 .grid-section {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
     gap: 9px;
 }


 .progress-container {
     width: 100%;
     background-color: #e0e0e0;
     border-radius: 10px;
     margin-bottom: 12px;
     height: 17px;
     margin-top: 63px;
     padding: 4px;
 }

 .progress-bar {
     width: 0;
     height: 100%;
     background-color: #103D56;
     border-radius: 10px;
     text-align: center;
     color: white;
     line-height: 20px;
     font-size: 14px;
     transition: width 0.4s ease;
 }



 /*  remove this */

 .over-ride-cmn {
     margin-top: 30px;
 }

 .steps-heading p {
     font-size: 16px;
     font-weight: 600;
     line-height: 26px;
     color: #103D56;
 }

 .steps-heading p span {
     font-size: 14px;
     font-weight: 400;
     line-height: 23px;
     color: #9D9D9D;
 }

 .personal-heading h3 {
     font-size: 20px;
     font-weight: 600;
     line-height: 26px;
     color: #000;
 }

 .age-box {
     display: flex;
     gap: 5px;
 }

 .age-box input {
     height: 41px;
 }

 /* .vidoe-link-box {
     display: flex;
     width: 100%;
     justify-content: space-between;
     gap: 20px;
 } */

 .link-videos {
     width: 100%;
 }

 .availability {
     width: 100%;
 }

 .uplode-click-box-main {
     margin-top: 23px;
     width: 100%;
 }

 .box-flex-up {
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 20px;
 }

 .players-cv {
     width: 100%;
 }

 /* .carrier-notes {
     display: flex;
     gap: 20px;
 } */

 .trophies {
     width: 50%;
 }

 .all-notes {
     width: 100%;
 }

 .new-bottom-flex {
     display: flex;
     align-items: center;
     justify-content: space-between;
     gap: 22px;
 }

 .profile-width {
     width: 100%;
 }

 .increment-decrement {
     display: flex;
     align-items: start;
     justify-content: space-between;
     gap: 10px;
 }

 .agents-player {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;

 }

 .player-field {
     flex: 0 49%;
     max-width: 49%;
 }

 .pro-relation {
     display: flex;
     gap: 20px;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     align-items: flex-end;
 }

 .fist-left-flex {
     display: flex;
     align-items: center;
     justify-content: space-between;
     gap: 20px;
     align-items: center;
 }

 .brought-current {
     width: 100%;
 }

 .agent-name-box {
     display: flex;
     justify-content: space-between;
     gap: 20px;
 }

 button.back-btn-ps {
     background: transparent;
     color: #103D56;
     box-shadow: 0px 2px 20px 0px #00239526;
     padding: 7px 79px;
 }

 button.next-btn-ps {
     padding: 7px 50px;
     margin-left: 15px;
 }

 .preview {
     display: flex;
     gap: 15px;
 }

 .preview-content img {
     width: 28px;
     height: 28px;
 }

 .content-second-row {
     display: flex;
     justify-content: space-between;
     gap: 10px;

 }

 .buyout-ps {
     display: flex;
     align-items: center;
     gap: 12px;
     width: 75%;
     justify-content: space-between;
     gap: 12px;
 }

 .full-width {
     width: 49%;
 }

 .other-notes-box {
     width: 100%;
 }

 .grid-section-player {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     gap: 9px;
 }

 /* .grid-section-player> :last-child {
     grid-column: span 2;
 } */

 .pro-posals-ps {
     display: flex;
     gap: 20px;
     justify-content: space-between;
 }

 .pro-posals-left {
     max-width: 49%;
     flex: 0 49.3%;
     padding-right: 10px;
     position: relative;
 }

 .pro-posals-left:before {
     content: "";
     position: absolute;
     top: 5px;
     right: -15px;
     background: #103d56;
     width: 1px;
     height: 100%;
 }

 .pro-posals-right {
     max-width: 49%;
     flex: 0 49.3%;
     padding-left: 10px;
 }

 h3.total-app {
     margin: 0px;
     white-space: nowrap;
 }

 .grid-statistics-section {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
     column-gap: 9px;
 }



 .market-link {
     display: block;
 }

 .market-link input {
     border: none;
     box-shadow: 0px 2px 20px 0px #00239526;
 }

 /* date picker */
 .age-box input[type="date"] {
     position: relative;
 }

 .age-box input[type="date"]::-webkit-calendar-picker-indicator {
     color: transparent;
     background-image: url("../images/new-calendar.svg");
     background-repeat: no-repeat;
     z-index: 1;
     cursor: pointer;
 }

 .add-new-date-icon input[type="date"] {
     position: relative;
 }

 .add-new-date-icon input[type="date"]::-webkit-calendar-picker-indicator {
     color: transparent;
     background-image: url("../images/new-calendar.svg");
     background-repeat: no-repeat;
     z-index: 1;
     cursor: pointer;
 }

 /* end */

 .profile-card .css-1u9des2-indicatorSeparator {
     background-color: transparent;
 }

 .profile-card .css-1xc3v61-indicatorContainer {
     color: #1D1617;
 }

 .form-check input[type=checkbox]:checked {
     background-color: #103D56;
     border: 1px solid #103D56;
     opacity: 1;
 }

 .form-check .form-check-input:focus {
     box-shadow: none;
     border: 1px solid #103D56;
 }

 /* .form-check .form-check-input[type=checkbox] {
            border: 1px solid #103D56;
        } */

 .new-video-link input::placeholder {
     color: #BEBEBE;
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
 }

 .new-video-link input {
     border: none;
     border-radius: 10px;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .new-radio-btn .form-check-input[type=radio] {
     border-radius: 50%;
     border: 1px solid #103D56;
     background: transparent;
 }

 .new-radio-btn .form-check-input:checked[type=radio] {
     background-image: url(../images/ellipse-icon.svg);
     background-repeat: no-repeat;
     background-position: 50% 50%;
 }

 .out-of p {
     white-space: nowrap;
 }

 .edit-img {
     position: absolute;
     top: -3px;
     right: 4px;
 }




 /* private pol */
 .privacy_text h1 {
     font-size: 40px;
     font-weight: 700;
 }

 .privacy_text h4 {
     font-size: 22px;
     font-weight: 700;
     padding-bottom: 16px;
 }

 .privacy_text h2 {
     padding-top: 15px;
     font-size: 33px;
     font-weight: 700;
     padding-bottom: 20px;

 }

 .privacy_text h3 {
     font-size: 22px;
     font-weight: 700;
 }

 .privacy_text p {
     font-size: 16px;
     font-weight: 400;
     line-height: 29px;
 }

 .privacy_text {
     padding-bottom: 50px;
     padding-top: 50px;
 }

 .container-tem {
     padding-right: 15px;
     padding-left: 15px;
     margin-right: auto;
     margin-left: auto;
 }

 .sub_points {
     list-style: decimal;
     padding: 0 0 0 25px;
 }


 /* new css add first page  */

 .label-text-up label {
     color: var(--Black-color, #1D1617);
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
 }

 .save-and-close-btn button {
     border-radius: 10px;
     background: #FFF;
     box-shadow: 0px 2px 20px 0px rgba(0, 35, 149, 0.15);
     color: #103D56;
     text-align: center;
     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     line-height: 27px;
 }


 .custom-select__placeholder {
     color: #BEBEBE !important;
     font-weight: 400 !important;
 }


 .add-team-btn {
     text-align: center;
     display: flex;
     justify-content: right;
     gap: 10px;
     margin-top: 36px;
 }

 .add-team-btn button {
     font-weight: 400;
     line-height: 27px;
     text-align: center;
     background: #103D56;
     color: #fff;
     border: none;
     padding: 7px 32px;
     border-radius: 7px;
     min-width: 150px;
 }

 .add-new-input {
     margin-top: 8px;
 }

 .form-control:focus {
     box-shadow: none;
     border: 1px solid rgba(0, 0, 0, 0.20);
 }

 .new-grid-system {
     grid-template-columns: 1fr 1fr 1fr 1fr;
 }

 .num-inputs.outer h3 {
     color: var(--Black-color, #1D1617);
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
 }

 .current-injuries-ps {
     display: flex;
     gap: 20px;
 }

 .post-new-injuries {
     width: 100%;
 }

 .post-flex-ps {
     display: flex;
     gap: 20px;
 }

 .sponsor-player {
     display: flex;
     gap: 20px;
     width: 100%;
 }

 .player-main-div {
     width: 100%;
 }

 .sponsor-main-div {
     width: 100%;
 }

 .managing-agents {
     display: flex;
     gap: 20px;
     width: 100%;
 }

 .heding-add-new h3 {
     color: #000;
     font-family: Oswald;
     font-size: 20px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
 }

 .add-new-input input::placeholder {
     color: #BEBEBE;
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
 }

 button.add-modal-btn-ps {
     border-radius: 10px;
     background: #FFF;
     box-shadow: 0px 2px 20px 0px rgba(0, 35, 149, 0.15);
     color: #103D56;
     text-align: center;
     font-size: 16px;
     font-style: normal;
     font-weight: 500;
     line-height: 23px;
 }

 h3.health-sub-heading {
     color: var(--Black-color, #1D1617);
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     margin: 0px;
 }


 /* Custom DatePicker Styles */
 .custom-datepicker {
     font-family: 'Arial, sans-serif';
     border-radius: 8px;
     padding: 10px;
     border: 1px solid #ced4da;
 }

 .custom-datepicker__input {
     width: 100%;
     padding: 10px;
     border-radius: 5px;
     border: 1px solid #ced4da;
     background-color: #fff;
     font-size: 14px;
 }

 .custom-datepicker__input:focus {
     border-color: #80bdff;
     outline: none;
     box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
 }

 .react-datepicker__day-name {
     color: #fff;
     font-family: Oswald;
 }

 .react-datepicker__current-month {
     color: #fff;
     font-family: Oswald;
 }

 .react-datepicker {
     font-size: 14px;
     border-radius: 10px;
     box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
 }

 .react-datepicker__header {
     background-color: #103d56;
     color: #fff;
     border-bottom: none;
     border-radius: 10px 10px 0 0;
 }

 .react-datepicker__day--selected,
 .react-datepicker__day--keyboard-selected {
     background-color: #103D56;
     color: #fff;
     border-radius: 50%;
 }

 .react-datepicker__day:hover {
     background-color: #103D56;
     color: #fff;
 }

 .player-age {
     width: 100%;
     max-width: 90px;
     text-align: center;
 }

 .age-box .react-datepicker-wrapper {
     width: 100%;
 }

 .age-box .custom-datepicker__input:focus {
     outline: none;
     box-shadow: none !important;
     border: 1px solid rgba(0, 0, 0, 0.20) !important;
 }


 .custom-datepicker select.react-datepicker__month-select {
     box-shadow: 0px 2px 20px 0px #00239526;
     background-color: #fff;
     padding: 5px 10px;
     border: none;
     font-size: 14px;
     font-weight: 400;
     line-height: 17px;
     border-radius: 10px;
     font-family: Oswald;
     margin-top: 9px;
 }

 .custom-datepicker select.react-datepicker__year-select {
     box-shadow: 0px 2px 20px 0px #00239526;
     background-color: #fff;
     padding: 5px 10px;
     border: none;
     font-size: 14px;
     font-weight: 400;
     line-height: 17px;
     border-radius: 10px;
     font-family: Oswald;
     margin-top: 9px;
 }

 .custom-datepicker .react-datepicker__navigation-icon--next::before {
     transform: rotate(45deg);
     left: -13px;
     top: 17px !important;
 }

 .custom-datepicker .react-datepicker__navigation--previous {
     left: 6px;
     top: 16px !important;
 }

 .bottom-lines {
     border-bottom: 1px solid #103d56;
     padding-bottom: 10px;
 }

 .grid-players {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     gap: 9px;
 }



 .add-new-date-icon .react-datepicker-wrapper {
     display: inline-block;
     padding: 0;
     border: 0;
     width: 100%;
 }

 .custom-datepicker__input:focus {
     outline: none;
     box-shadow: none !important;
     border: 1px solid rgba(0, 0, 0, 0.20) !important;
 }

 .form-inner .react-datepicker-wrapper {
     width: 100%;
 }

 .search-inner-box ul li:last-child {
     border-bottom: 0px;
 }

 .add-new-pd {
     padding: 12px 0px;
     margin: 0px;
 }

 h3.add-new-pd {
     color: #000;
     font-family: Oswald;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
 }

 .play-inner-box:last-child {
     border-bottom: none;
 }

 .managing-comp h2 {
     font-size: 15px;
     font-weight: 600;
     line-height: 24px;
 }

 .add-back-ground-color {
     border-radius: 10px;
     background: #FFF;
     box-shadow: 0px 2px 20px 0px rgba(0, 35, 149, 0.15);
 }

 .search-inner-box ul li.injury-name-flex {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     gap: 6px;
     padding: 0px;
 }

 p.past-injury {
     width: 100%;
 }

 .one-more-grid {
     grid-column: 1 / 4;
 }

 p.position-right-side {
     max-width: 230px;
 }

 .kevi-profile img {
     width: 55px;
     height: 55px;
     object-fit: cover;
     border-radius: 10px;
 }

 .player-agent-search-ag {
     box-shadow: none;
 }

 .player-agent-search-ag ul li {
     padding: 0px;
 }

 .player-agent-search-ag {
     margin-top: 10px;
     padding-top: 10px;
     border-top: 1px solid #DEDEDE;
     border-radius: 0px;
 }

 .per-vious-player-team {
     box-shadow: none;
 }

 .per-vious-player-team ul li {
     padding-top: 0px;
 }

 li.injury-name-flex p {
     padding: 6px 6px;
 }

 p.health-problem-box {
     width: 100%;
 }