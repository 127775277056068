@media (max-width: 1700px) {
    .header-bottom-main {

        width: 85%;

    }


}

@media(max-width:1500px) {
    .header-bottom-main {

        width: 90%;

    }
}

@media (max-width: 1399.98px) {
    .login-right-head {
        gap: 20px;
    }

    .search-input input {
        width: 300px;
    }

    .header-bottom-tab .pe-0 {
        padding-right: 12px !important;
    }
}

@media (max-width: 1299.99px) {
    .licensed-sec .form-check.form-switch {
        padding: 0;
        width: 50px;
        display: flex;
        justify-content: end;
    }

    .main-background-image .slick-arrow.slick-next {
        position: fixed;
        bottom: 50px !important;
        left: 0;
        z-index: 11;
        top: unset !important;
        right: 0;
        margin: auto;
    }

    .third-form {
        margin-top: 0;
    }

    .third-slide .top-h {
        margin-top: 21px;
    }

    .slick-slide img {
        display: block;
        margin-top: 17px;
    }

    .header-bottom-main {
        width: 100%;
    }
}

@media (max-width: 1199.98px) {
    .container-tem {
        width: 1180px;
    }

    .fist-slide>img {
        width: 400px;
    }

    .fist-slide h3 {
        line-height: 2;
        margin-top: 15px;
    }

    .third-slide h3 {
        line-height: 1.6;
        font-size: 14px;

    }

    .third-form {
        margin-top: 15px;
    }

    .main-background-image .slick-arrow.slick-next {

        bottom: 40px !important;

    }

    .third-slide .top-h {
        margin-top: 15px;
    }

    .main-background-image .slick-dots li button:before {
        width: 25px;
        border-radius: 37px;
        border: 2px solid #fff;
        height: 25px;
        opacity: 1;
    }

    .main-background-image .slick-dots .slick-active button:before {
        color: #fff;
        width: 25px;
        border-radius: 37px;
        height: 25px;
        opacity: 1;
        padding: 2px 2px 1px 1px;
        font-size: 19px;
    }

    .login-right-head {
        gap: 20px;
        padding-top: 25px;
        width: 600px;
        justify-content: end;
    }

    .login-right-head .dropdown-menu.show {
        transform: translate(40px, 8px) !important;
    }

    .header-inner-left {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
    }

    .search-input input {
        width: 100%;
    }

    .input-filter-box {
        position: relative;
        z-index: 11;
        width: 100%;
    }

    .add-banner-desc h2 {

        max-width: 150px;

    }

    .players-value h3 {

        font-size: 25px;

    }

    .header-bottom-tab a {

        font-size: 14px;

    }

    .header-bottom-tab a {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-bottom-tab {
        flex-wrap: wrap;
        background: #fff;
    }

    .header-bottom-main {

        top: 22px;

    }
}

@media (max-width: 991.98px) {
    .container-tem {
        width: 970px;
    }

    .players-value {

        width: calc(100% - 33px);

    }

    .players-value h3 {
        font-size: 20px;
    }

    .play-images {
        position: relative;
        margin-bottom: 20px;
    }

    .add-banner-desc h2 {
        max-width: 100%;
        width: 100%;
    }

    .common-outer .image-upload-main {
        margin-bottom: 20px;
    }

    .image-upload-main {
        position: relative;
        margin-bottom: 15px;
    }
}

@media (max-width: 767.98px) {
    .container-tem {
        width: 750px;
    }

    .main-background-image .slick-dots {
        display: none !important;
    }

    .fist-slide>img {
        width: 100%;
    }

    .third-slide h3 {

        text-align: center;
    }

    .home-tab-filter ul {
        padding: 0;
        list-style: none;
        display: flex;
        gap: 20px;
        margin: 0;
    }

    .footer-menu {
        gap: 30px;
        margin: 40px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .transfer-box {

        width: 600px;

    }

    .play-card-sec {
        overflow: auto;
    }

    .cmn-light-btn button {

        padding: 12px 91px;

    }

    .player-upload-div label img {
        width: 100px;
        margin: auto;
        display: table;
    }

    .player-upload-div {

        width: 100%;
        height: 350px;

    }

    .compare-bottom {
        margin: 45px 35px 15px 35px;
    }

    .inner-header .container {
        max-width: 100%;
    }
}



@media (max-width: 575.98px) {
    .third-form input.form-control {
        width: 240px;
    }

    .filter-dropdowns a {
        padding: 5px 18px;

    }

    .search-input input {

        padding-right: 50px;

    }

    .home-tab-filter ul li a {

        font-size: 13px;

    }
}